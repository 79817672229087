import React, { useContext } from 'react';
import { enrollmentContext } from '@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context';
import context from '@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context';
import { RichText } from '@nvs/gatsby-theme-leap-core/src/components/hoc/components';

export default function IneligibilityContent() {
	const ctxt = useContext(context);
	const enrollmentCtxt = useContext(enrollmentContext);
    const isEligibleRegion:boolean=
    enrollmentCtxt.input.questions.some((question)=>question.id==="region"&&question.answer===true)
    enrollmentCtxt.resetInput();
	const isFastTrack = enrollmentCtxt.isFastTrack(ctxt);
   


    return (
        <div className="flex flex-col gap-5 text-lg text-left">
            {isFastTrack&&!isEligibleRegion?(<p><RichText html={ctxt.json.pages.ineligibility_region.title} /></p>):
            (<>
                <p><RichText html={ctxt.json.pages.ineligibility.title} /></p>
                <p><RichText html={ctxt.json.pages.ineligibility.description} /></p>
            </>)}
        </div>
    );
}
